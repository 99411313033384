import React from "react";
import { Link, navigate } from "gatsby";
import { getCatUrl } from "../lib/helpers";
import { cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/category-list.module.css";

const CategoryList = (props) => {

  const {
    items,
    category
  } = props;

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>News &amp; Updates</h1>
      <div className={styles.categoryList}>
        <ul>
          <li>
            <Link to="/news-updates" className={category ? undefined : styles.isCurrent}>All</Link>
          </li>
          {items.edges.map(({node: item}) => (
            <li key={item.id}>
              <Link to={getCatUrl(item.slug)} className={category === item.slug ? styles.isCurrent : undefined}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CategoryList;
