import React, { useLayoutEffect } from "react";
import Container from "./container";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { cn } from "../lib/helpers";
import NewsItem from "./news-item";
import CategoryList from "./category-list";
import Pagination from "./pagination";

import * as styles from "../styles/components/news-list.module.css";

const NewsList = (props) => {

  const {
    news,
    category,
    cats,
    currentPage,
    numPages
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#news-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#news-bg', {
        scrollTrigger: {
          trigger: 'body',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 150,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <div className={cn(styles.bg, "m-hide")} id="news-bg">
          <img src="/images/feature_woman-light.png" alt="" />
        </div>
        <div className="grid">
          <div className="col-12 col-md-6">
            {cats && (<CategoryList items={cats} category={category} />)}
          </div>
          <div className="col-12 col-md-6">
            <div className={styles.newsList}>
              {news.map(({node: news}) => (
                <div key={news.id} className={styles.item}>
                  <NewsItem news={news} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {numPages > 1 && (<Pagination base={category ? "/news-updates/"+category : "/news-updates"} currentPage={currentPage} numPages={numPages} />)}
      </Container>
    </div>
  );
}

export default NewsList;
