import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import NewsList from "../components/news-list";

export const query = graphql`
  query NewsPageQuery($skip: Int!, $limit: Int!) {
    newsPage: sanityNewsPage {
      title
      description
    }

    news: allSanityNews(
      sort: { fields: [publishedOn], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          category {
            title
            slug {
              current
            }
          }
          publishedOn
          slug {
            current
          }
          externalLink {
            text
            url
          }
          documentLink {
            text
            document {
              asset {
                url
                originalFilename
              }
            }
          }
        }
      }
    }

    cats: allSanityCategory(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const NewsPageTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const newsPage = (data || {}).newsPage;
  const cats = (data || {}).cats;
  const news = (data || {}).news.edges;

  return (
    <Layout>
      <Seo
        title={newsPage.title}
        description={newsPage.description}
      />

      {news && <NewsList news={news} cats={cats} currentPage={currentPage} numPages={numPages} />}
    </Layout>
  );
};

export default NewsPageTemplate;
