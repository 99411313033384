import React from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import { format } from "date-fns";
import { Link } from "gatsby";
import { getCatUrl } from "../lib/helpers";
import { getNewsUrl } from "../lib/helpers";

import * as styles from "../styles/components/news-item.module.css";

const NewsItem = (props) => {

  const {
    news
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.meta}>
        {news.publishedOn && (
          <span className={styles.date}>{format(new Date(news.publishedOn).setDate(new Date(news.publishedOn).getDate() + 1), "MM/dd/yy")}</span>
        )}
        <span className={styles.cat}><Link to={getCatUrl(news.category.slug)}>{news.category.title}</Link></span>
      </div>

      <h2 className={cn("h2-lg", styles.title)}>
        {news.slug ? (
          <Link to={getNewsUrl(news.category, news.slug)}>{news.title}</Link>
        ) : (news.externalLink && news.externalLink.url) ? (
              <a href={news.externalLink.url} target="_blank" rel="noreferrer">{news.title}</a>
            ) : (news.documentLink && news.documentLink.document) ? (
                  <a href={news.documentLink.document.asset.url + "/" + news.documentLink.document.asset.originalFilename} target="_blank" rel="noreferrer">{news.title}</a>
                ) : news.title
        }
      </h2>

      <div className={styles.linksWrap}>
        {((news.slug && !(news.externalLink && news.externalLink.url) && !(news.documentLink && news.documentLink.document)) || 
          (news.slug && (news.externalLink && news.externalLink.url) && !(news.documentLink && news.documentLink.document)) || 
          (news.slug && !(news.externalLink && news.externalLink.url) && (news.documentLink && news.documentLink.document))) && (
          <Link className="link" to={getNewsUrl(news.category, news.slug)}>View Article<Icon symbol="arrow-right" /></Link>
        )}

        {(news.externalLink && news.externalLink.url) && (
          <a className="link" href={news.externalLink.url} target="_blank" rel="noreferrer">{news.externalLink.text}<Icon symbol="external" /></a>
        )}

        {(news.documentLink && news.documentLink.document) && (
          <a href={news.documentLink.document.asset.url + "/" + news.documentLink.document.asset.originalFilename} className="link" target="_blank" rel="noreferrer">{news.documentLink.text}<Icon symbol="external" /></a>
        )}
      </div>
    </div>
  );
}

export default NewsItem;
